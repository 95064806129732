import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';

function RefundTableCharges({ recevables }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1); // Material-UI Pagination uses 1-based indexing
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); 
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Handle empty date strings (if any)
    }

    const formattedDate = new Date(dateString);
    if (isNaN(formattedDate)) {
      return dateString; // Return the original string if it's not a valid date
    }

    return formattedDate.toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
  };
  return (
    <div>
      <h2>Refund Data</h2>
      <br></br>
      <br></br>
      <TableContainer component={Paper} style={{ width: '78%', marginLeft: '15%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>invoiceNo</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Paid Dues</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recevables?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((recevable, index) => (
                <TableRow key={recevable._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{recevable.invoiceNo}</TableCell>
                  <TableCell>{recevable.CustomerName}</TableCell>
                  <TableCell>{recevable.Amount}</TableCell>
                  <TableCell>{recevable.userName}</TableCell>
                  <TableCell>{formatDate(recevable.CurrentDate)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination style={{ width: '70%', marginLeft: '40%' }}
        count={Math.ceil(recevables.length / rowsPerPage)}
        page={page + 1} // Material-UI Pagination uses 1-based indexing
        onChange={handleChangePage}
      />
    </div>
  );
}

export default RefundTableCharges;
