import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Alert,
} from '@mui/material';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '@mui/material/Pagination';

const ServiceTypeForm = () => {
    const [serviceTypes, setServiceTypes] = useState([]);
    const [newServiceType, setNewServiceType] = useState('');
    const [editingServiceType, setEditingServiceType] = useState({ id: '', name: '' });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); 
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);


    useEffect(() => {
        fetchServiceTypes();
    }, []);

    const fetchServiceTypes = async () => {
        try {
            const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/serviceTypes');
            setServiceTypes(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCreateServiceType = async () => {
        try {
            await axios.post('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/serviceTypes', { Name: newServiceType });
            setNewServiceType('');
            fetchServiceTypes();
            setSuccessMessage('Service Type created successfully.');
            setErrorMessage('');
        } catch (error) {
            console.error(error);
            setErrorMessage('An error occurred while creating the Service Type.');
            setSuccessMessage('');
        }
    };

    const handleEditServiceType = async () => {
        try {
            await axios.put(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/serviceTypes/${editingServiceType.id}`, { Name: editingServiceType.name });
            setEditingServiceType({ id: '', name: '' });
            fetchServiceTypes();
            setSuccessMessage('Service Type updated successfully.');
            setErrorMessage('');
        } catch (error) {
            console.error(error);
            setErrorMessage('An error occurred while updating  the Service Type.');
            setSuccessMessage('');
        }
    };

    const handleDeleteServiceType = async (id) => {
        try {
            await axios.delete(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/serviceTypes/${id}`);
            fetchServiceTypes();

            setSuccessMessage('Deleted successfully.');
            setErrorMessage('');
        } catch (error) {
            console.error(error);
            setErrorMessage('An error occurred while deleting  the Service Type.');
            setSuccessMessage('');
        }
    };
    const filteredServiceType = serviceTypes.filter((airline) =>
        airline.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
      };
    // Calculate the indices for the pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentServiceType = filteredServiceType.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <div>
            {successMessage && (
                <Alert severity="success" onClose={() => setSuccessMessage('')}>
                    {successMessage}
                </Alert>
            )}

            {errorMessage && (
                <Alert severity="error" onClose={() => setErrorMessage('')}>
                    {errorMessage}
                </Alert>
            )}

            <h2 style={{ marginRight: '16px' }}>Service Types Screen</h2>

            <br></br>
            <hr></hr>
            <br></br>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: '32%' }}>
                <TextField
                    label="New Service Type"
                    variant="outlined"
                    value={newServiceType}
                    onChange={(e) => setNewServiceType(e.target.value)}
                />
                <Button variant="contained" color="primary" style={{ width: '11%', marginLeft: '11%' }} onClick={() => {
                    if (newServiceType.trim() !== '') {
                        handleCreateServiceType();
                    } else {
                        // Handle the case where the input is empty
                        setErrorMessage('Service Type cannot be empty');
                    }
                }}
                >
                    Create
                </Button>
            </div>

            <br></br>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: '32%' }}>
                <TextField
                    style={{ width: '47%' }}
                    label="Search Service Type By Name"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            <TableContainer component={Paper} style={{ width: '30%', marginLeft: '30%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentServiceType.map((serviceType) => (
                            <TableRow key={serviceType._id}>
                                <TableCell>
                                    {editingServiceType.id === serviceType._id ? (
                                        <TextField
                                            value={editingServiceType.name}
                                            onChange={(e) => setEditingServiceType({ ...editingServiceType, name: e.target.value })}
                                        />
                                    ) : (
                                        serviceType.Name
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingServiceType.id === serviceType._id ? (
                                        <Button variant="contained" color="primary" onClick={handleEditServiceType}>
                                            Save
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary" style={{ width: '15%', marginLeft: '4%' }}
                                                onClick={() => setEditingServiceType({ id: serviceType._id, name: serviceType.Name })}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary" style={{ width: '15%', marginLeft: '4%' }}
                                                onClick={() => handleDeleteServiceType(serviceType._id)}
                                            >
                                                Delete
                                            </Button>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <Pagination
                    count={(filteredServiceType.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </div>

            <ToastContainer />
        </div>
    );
};

export default ServiceTypeForm;
