import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserRegistration from './UserRegistration';
import './Userlist.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  button: {
    marginRight: '10px',
  },
});

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRoles, setUserRole] = useState('');
  const [showPassword, setShowPassword] = useState(''); 
  const [userRole, setUserRoles] = useState('');
  const token=localStorage.getItem('token');
  const classes = useStyles();
  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setUserRoles(logedInUser.Role);
    }
  }, []);
  useEffect(() => {
    getUsers();
  }, []);
  
  const getUsers = async () => {
    try {
      const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/users', 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }});
      const data = response.data;
      setUsers(data);
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error:', error)
    }finally{
    }
  };

  // Function to handle registration
  const handleRegistration = (message) => {
    console.log(message); // Display the registration message (success or failure)
    getUsers(); // Update the user list after registration
  };

  const handleEditUser = (userId) => {
    const user = users.find((user) => user._id === userId);
    setEditingUserId(userId);
    setUserName(user.Name); // Initialize the state values with the user's data
    setUserEmail(user.Email);
    setUserRole(user.Role);
    setShowPassword(user.Password);
  };

  const handleUpdateUser = async (userId, updatedUserData) => {
    try {
      await axios.put(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/users/${userId}`, updatedUserData
      , 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }});
      setEditingUserId(null);
      getUsers();
      
      toast.success("udpate successfully",userId);
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/users/${userId}`
      , 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }});
      getUsers();
      
      toast.success("deleted successfully",userId);
    } catch (error) {
      console.error('Error deleting user:', error);
      
      toast.error('Error deleting user:', error);
    }
  };
  return userRole === 'Admin' ? (
    <div className="customer-container">
      <div className="left-column">
        <div className="title-bar">
          <h2 className="user-list-heading">User List</h2>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Password</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id} className="user-row">
                  <TableCell>
                    {editingUserId === user._id ? (
                      <input
                        type="text"
                        onChange={(e) => setUserName(e.target.value)}
                        defaultValue={user.Name}
                      />
                    ) : (
                      user.Name
                    )}
                  </TableCell>
                  <TableCell>
                    {editingUserId === user._id ? (
                      <input
                        type="text"
                        onChange={(e) => setUserEmail(e.target.value)}
                        defaultValue={user.Email}
                      />
                    ) : (
                      user.Email
                    )}
                  </TableCell>
                  <TableCell>
                    {editingUserId === user._id ? (
                      <input
                        type="password"
                        onChange={(e) => setShowPassword(e.target.value)}
                        defaultValue={user.Password}
                      />
                    ) : (
                      '********'
                    )}
                  </TableCell>
                  <TableCell className='from-row'>
                    {editingUserId === user._id ? (
                      <FormControl>
                        <InputLabel>Role</InputLabel>
                        <Select
                          value={userRoles}
                          onChange={(e) => setUserRole(e.target.value)}
                        >
                          <MenuItem value="Admin">Admin</MenuItem>
                          <MenuItem value="User">User</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      user.Role
                    )}
                  </TableCell>
                  <TableCell>
                    {editingUserId === user._id ? (
                      <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() =>
                          handleUpdateUser(user._id, {
                            Name: userName,
                            Password: showPassword,
                            Email: userEmail,
                            Role: userRoles,
                          })
                        }
                      >
                        Save
                      </Button>
                    ) : (
                      <>
                        <Button 
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          style={{ width: "20%", marginLeft: "3%" }}
                          onClick={() => handleEditUser(user._id)}
                        >
                          Edit
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          style={{ width: "20%", marginLeft: "3%" }}
                          onClick={() => handleDeleteUser(user._id)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='right-column'>
        <div className="title-bar">
          <h2 className="user-list-heading">Create New Registration Form</h2>
        </div>
        <UserRegistration userRole={userRole} onRegistration={handleRegistration} />
      </div>
    </div>
  ) : null;
};

export default UserList;
