import React, { useState, useEffect, useRef } from 'react';
import './MainScreen.css'; // Import the CSS file for styling
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HotelForm from './Hotel/HotelForm.js';
import VisaForm from './Visa/VisaForm.js';
import MedicalForm from './Medical/MedicalForm.js';
import TicketForm from './Tickets/TicketForm.js';
import RentCarForm from './RentCar/RentCarForm.js'
import axios from "axios";
import Insurrance from './InsuranceForm/InsuranceForm.js'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import PrintableComponent from "./PrintableComponent";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from "react-to-print";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import GenericServiceForm from './GenericServiceForm'



import Grid from '@mui/material/Grid';
import { ThemeProvider, TextField, FormControl, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';

import FormControlLabel from '@mui/material/FormControlLabel';

function MainScreen({ userRole }) {
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServiceArray, setSelectedServiceArray] = useState([]);
  const [totalPurchasePrice, setTotalPurchasePrice] = useState(0);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const token = localStorage.getItem('token');
  const logedInUser = localStorage.getItem('token');

  const [servicesList, setServiceTypes] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const printableInvoiceRef = useRef(null);
  const [openError, setOpenError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [serviceData, setServiceData] = useState([]);
  const [dueAmmount, setDueAmmount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const navigate = useNavigate();

  const [invoiceData, setInvoiceData] = useState({
    inID: '',
    customerName: '',
    Total: '',
    orderItems: [],
  });
  const [formData, setFormData] = useState({
    customerName: '',
    Cash: '',
    selectDate:'',
  });
  const [ticketData, setticketData] = useState({
    Purchased: '',
    Sales: '',
    Description: '',
    RefernceNo: '',
  });
  const [hotelData, sethotelData] = useState({
    Purchased: '',
    Sales: '',
    Description: '',
    RefernceNo: '',
  });
  const handleCheckboxChange = (service) => {
    const updatedServices = [...selectedServices];
    if (updatedServices.includes(service)) {
      updatedServices.splice(updatedServices.indexOf(service), 1); // Deselect
    } else {
      updatedServices.push(service); // Select
    }
    setSelectedServices(updatedServices);
    sethotelData({
      Purchased: '',
      Sales: '',
      Description: '',
      RefernceNo: '',
    });
  };
  useEffect(() => {
    const invoiceDataForPrinting = {
      customerName: formData.customerName,
      Cash: formData.Cash,
      TotalPurchasedPrice: totalPurchasePrice,
      TotalSalesPrice: totalSalePrice,
      date: new Date().toISOString().slice(0, 10),
      selectedServiceArray,
    };
    setInvoiceData(invoiceDataForPrinting);
  }, [selectedServiceArray, formData, totalPurchasePrice, totalSalePrice]);
  const handleTicketChange = (e) => {
    const { name, value } = e.target;
    setticketData({
      ...ticketData,
      [name]: value,
    });
  };
  const handleHotelChange = (e) => {
    const { name, value } = e.target;
    sethotelData({
      ...hotelData,
      [name]: value,
    });
  };
  useEffect(() => {
    fetchServiceTypes();
  }, []);
  const fetchServiceTypes = async () => {
    try {
      const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/serviceTypes');
      setServiceTypes(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  function getFormComponent(service) {

    switch (service) {
      case 'Ticket Sales':
        return <TicketForm ticketData={ticketData} onTicketChange={handleTicketChange} />;;
      default:
        return <HotelForm hotelData={hotelData} service={service} onHotelChange={handleHotelChange} />;
    }
  }
  const handleServiceChange = (service, data) => {
    const serviceIndex = serviceData.findIndex((serviceItem) => serviceItem.service === service);
    console.log('Service index:', serviceIndex);

    if (serviceIndex === -1) {
      // If the service doesn't exist in the array, add it
      setServiceData((prevData) => [...prevData, { service, data }]);
    } else {
      setServiceData((prevData) => {
        const updatedData = [...prevData]; // Create a copy of the existing data
        updatedData[serviceIndex].data = data; // Update the data for the existing service
        return updatedData; // Return the updated data as the new state
      });
    }
  };
  useEffect(() => {
    const total = calculateTotalPurchasedAmount();
    setTotalSalePrice(total);

    const dues = total - totalPaid;
    setDueAmmount(dues);
    const totalPurchasePrice = calculateSalePrice();
    setTotalPurchasePrice(totalPurchasePrice);
  }, [selectedServiceArray]);
  const addServiceToArray = (newServiceData) => {
    setSelectedServiceArray((prevData) => {
      // Check if the newServiceData is already present in the array
      const isDuplicate = prevData.some((existingItem) => {
        return (
          newServiceData.service === existingItem.service &&
          JSON.stringify(newServiceData.data) === JSON.stringify(existingItem.data)
        );
      });

      // If it's not a duplicate, add it to the array
      if (!isDuplicate) {
        return [...prevData, newServiceData];
      }

      return prevData; // If it's a duplicate, do not add it again
    });
  };
  const handleAddItem = () => {

    const newData = serviceData;
    newData.forEach((serviceItem) => {
      const existingItem = selectedServiceArray.find((item) => item.service === serviceItem.service);

      if (!existingItem) {
        // If the service doesn't exist in selectedServiceArray, add it
        setSelectedServiceArray((prevData) => [...prevData, serviceItem]);
      } else {
        // If the service exists, check if sales, purchase, or description is different
        if (
          existingItem.data.Sales !== serviceItem.data.Sales ||
          existingItem.data.Purchased !== serviceItem.data.Purchased ||
          existingItem.data.Description !== serviceItem.data.Description
        ) {
          // If any one of them is different, update the service in selectedServiceArray
          setSelectedServiceArray((prevData) => [...prevData, serviceItem]);
        }
      }
    });
    setServiceData([]);
    const invoiceDataForPrinting = {
      customerName: formData.customerName,
      Cash: formData.Cash,
      TotalPurchasedPrice: totalPurchasePrice,
      TotalSalesPrice: totalSalePrice,
      date: new Date().toISOString().slice(0, 10),
      selectedServiceArray,
    };
    setInvoiceData(invoiceDataForPrinting);
  };
  const handleDelete = (index) => {
    setSelectedServiceArray((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });
  };
  const calculateTotalPurchasedAmount = () => {
    let total = 0;
    selectedServiceArray.forEach((item) => {
      total += parseFloat(item.data.Sales) || 0;
    });
    setTotalSalePrice(total);
    const dues = total - totalPaid;
    setDueAmmount(dues);
    return total;
  };
  const calcualteTotal = (e) => {
    const value = e.target.value;
    let total = 0;
    selectedServiceArray.forEach((item) => {
      total += parseFloat(item.data.Sales) || 0;
    });
    const dues = total - value;
    setDueAmmount(dues);
  }
  const calculateSalePrice = () => {
    let total = 0;
    selectedServiceArray.forEach((item) => {
      total += parseFloat(item.data.Purchased) || 0;
    });
    setTotalPurchasePrice(total);
    return total;
  }
  const headers = {
    'authorization': `Bearer ${token}`,// Replace with your actual authorization token
    'Content-Type': 'application/json', // You can set other headers as needed
  };
  const handleSaveItem = async () => {

    if (selectedServices.length === 0) {
      toast.error('Please select at least one service.', {
        position: 'top-right',
        autoClose: 3000, // Close the notification after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setOpenError(true);
      setTimeout(() => {
        setErrorMessage('');
        setOpenError(false);
      }, 3000);
      return;
    }

    if (!formData.customerName || !formData.Cash) {
      toast.error('Please fill in all customer information fields.', {
        position: 'top-right',
        autoClose: 3000, // Close the notification after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setErrorMessage('Please fill in all customer information fields.');
      setOpenError(true);
      setTimeout(() => {
        setErrorMessage('');
        setOpenError(false);
      }, 3000);
      return;
    }
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    try {
      const apiUrl = "https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices";
      const response = await axios.post(apiUrl, {
        customerName: formData.customerName,
        Cash: formData.Cash,
        TotalPurchasedPrice: totalPurchasePrice,
        TotalSalesPrice: totalSalePrice,
        date: formData.selectDate,
        UserId: logedInUser._id,
        UserName: logedInUser.Name,
        TotalDue: dueAmmount,
        TotalPaid: totalPaid,
      });
      let InvoiceId = -1;
      console.log("response data : " + JSON.stringify(response.data));
      if (response.data) {
        InvoiceId = response.data._id;
      }
      const response1 = await axios.post("https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/orderItems", {
        InvoiceId,
        selectedServiceArray: selectedServiceArray,
      }
        , {
          headers: headers, // Include the headers object here
        });
      if (response.data) {
        toast.success('Successfully saved', {
          position: 'top-right',
          autoClose: 3000, // Close the notification after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setSuccessMessage('');
        setOpenSuccess(true);
        setTimeout(() => {
          setSuccessMessage('');
          setOpenSuccess(false);
        }, 3000);
        resetFormFields();
        window.location.href = '/';
      } else {
        setErrorMessage('Failed to save data');
        toast.error('Failed to save data', {
          position: 'top-right',
          autoClose: 3000, // Close the notification after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setOpenError(true);
        setTimeout(() => {
          setErrorMessage('');
          setOpenError(false);
        }, 3000);
      }
    } catch (error) {
      setErrorMessage('Error occurred while saving data');
      toast.error('Error occurred while saving data', {
        position: 'top-right',
        autoClose: 3000, // Close the notification after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setOpenError(true);
      setTimeout(() => {
        setErrorMessage('');
        setOpenError(false);
      }, 3000);
    }
  }
  const resetFormFields = () => {
    setFormData({
      customerName: '',
      Cash: '',
    });

    sethotelData({
      Purchased: '',
      Sales: '',
      Description: '',
      RefernceNo: '',
    });

    setSelectedServiceArray([]);
    setTotalPurchasePrice(0);
    setTotalSalePrice(0);
    setDueAmmount(0);
    setTotalPaid(0)
  };

  return (

    <div style={{ display: 'flex' }} className='border-element'>
      {/* Service List Section */}
      <div style={{ flex: 1, marginLeft: '1.5%' }}>
        <Typography variant="h6" textAlign={'left'} borderBottom={'3px solid #000'}>Services List</Typography>
        <br></br>
        {servicesList.map((service) => (
          <FormControlLabel
            key={service.Name}
            control={
              <Checkbox
                checked={selectedServices.includes(service.Name)}
                onChange={() => handleCheckboxChange(service.Name)}
              />
            }
            label={service.Name}
            labelPlacement="end"
            style={{ display: 'flex', alignItems: 'center' }}
          />
        ))}
      </div>


      <div style={{ flex: 3, marginLeft: '20px' }} className='border-element'>
        {/* Snackbar for Success Message */}
        <Snackbar open={openSuccess} autoHideDuration={3000} onClose={() => setOpenSuccess(false)}>
          <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setOpenSuccess(false)}>
            {successMessage}
          </MuiAlert>
        </Snackbar>

        <Typography variant="h6" textAlign={'left'} borderBottom={'3px solid #000'}>Customer Info</Typography>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              name="customerName"
              label="Customer Name"
              value={formData.customerName}
              onChange={handleChange}
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>

            <FormControl fullWidth>
              <InputLabel>Payment Type</InputLabel>
              <Select
                name="Cash"
                value={formData.Cash}
                onChange={handleChange}
                style={{ color: 'black' }}
                inputProps={{
                  style: { color: 'black' }, // Set the text color of the selected option
                }}
                fullWidth
              >
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Credit">Credit</MenuItem>
                <MenuItem value="POS- Doha Bank">POS- Doha Bank</MenuItem>
                <MenuItem value="Doha Bank">Doha Bank</MenuItem>
                <MenuItem value="QIB">QIB</MenuItem>
                <MenuItem value="Cash/ Credit">Cash/ Credit</MenuItem>
                <MenuItem value="Cash/ POS">Cash/ POS</MenuItem>
                <MenuItem value="POS/ Credit">POS/ Credit</MenuItem>
                <MenuItem value="Refund">Refund</MenuItem>
                <MenuItem value="Adjustment">Adjustment</MenuItem>
                <MenuItem value="Refund Adjustment">Refund Adjustment</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>

            <TextField
              label="Select Date"
              name="selectDate"
              type="date"
              value={formData.selectDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <br></br>
        <Grid container spacing={2}>
          <Grid container spacing={2}>
            {selectedServices.map((service) => (
              <Grid item xs={12} md={6} key={service}>
                <GenericServiceForm
                  service={service}
                  onServiceChange={handleServiceChange}></GenericServiceForm>
              </Grid>
            ))}
          </Grid>

        </Grid>

        <br></br>
        <Button variant="contained" color="primary" className='button_css' onClick={handleAddItem}>
          Add Item
        </Button>

        <br></br>

        <br></br>
        {selectedServiceArray.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Type of Service</TableCell>
                  <TableCell>Vendor</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>RefernceNo</TableCell>
                  <TableCell>Purchased Price</TableCell>
                  <TableCell>Sale Price</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedServiceArray?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.service}</TableCell>
                    <TableCell>{item.data.Vendor}</TableCell>
                    <TableCell>{item.data.Description}</TableCell>
                    <TableCell>{item.data.RefernceNo}</TableCell>
                    <TableCell>{item.data.Purchased}</TableCell>
                    <TableCell>{item.data.Sales}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>

                ))}
                <TableRow>
                  <TableCell colSpan={6}></TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>{totalSalePrice}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {/* Add row for Amount Paid */}
                <TableRow>
                  <TableCell colSpan={6}></TableCell>
                  <TableCell>Amount Paid</TableCell>
                  <TableCell><TextField name='due' type='number' value={totalPaid} onChange={(e) => {
                    setTotalPaid(e.target.value); calcualteTotal(e);
                  }} ></TextField></TableCell> {/* You need to define amountPaid */}
                  <TableCell></TableCell>
                </TableRow>
                {/* Add row for Balance Due */}
                <TableRow>
                  <TableCell colSpan={6}></TableCell>
                  <TableCell>Balance Due</TableCell>
                  <TableCell>{dueAmmount}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Typography variant="h6" visibility={selectedServiceArray.length > 0}>
          Total  Amount:
        </Typography>
        <Button variant='contained' disabled={selectedServiceArray.length === 0} color="primary" onClick={() => handleSaveItem()} className='button_css'> Save To DB</Button>
        <br></br>
        <br></br>
        <div>
          <ReactToPrint
            trigger={() => (
              <Button color="primary" disabled={selectedServiceArray.length === 0} variant='contained' style={{ width: '15%' }}>
                Quotation
              </Button>
            )}
            content={() => printableInvoiceRef.current}
          />
          <div style={{ display: "none" }}>
            <PrintableComponent
              invoiceData={invoiceData}
              ref={printableInvoiceRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainScreen;
