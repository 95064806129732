import React, { useState, useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PrintableInvoice from "./PrintableInvoice";
import ReactToPrint from "react-to-print";
import Pagination from "@mui/material/Pagination";
import ConfirmationDialog from './ConfirmationDialog';

import { TextField } from "@mui/material";

const RInvoiceTable = ({
  invoices,
  onDelete,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRefundChargesChange,
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedInvoices = invoices.slice(startIndex, endIndex);
  const [showPrintableInvoice, setShowPrintableInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const componentRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [refundCharges, setRefundCharges] = useState(0);
  const [refundCharge, setRefundCharge] = useState(0);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);


  const totalPages = Math.ceil(invoices.length / itemsPerPage);
  const highlightedRowStyle = {
    backgroundColor: 'lightblue', // Change the background color to your desired highlight color
  };
  const handlePrintClick = (invoice) => {
    console.log("Selected Invoice ID:", invoice._id);
    setSelectedInvoice(invoice);
    setShowPrintableInvoice(true);
  };

  const handleOnChangeRefund = (e) => {
    setRefundCharges(e.target.value);
    onRefundChargesChange(e.target.value);
  };
  const handleHidePrintableInvoice = () => {
    setShowPrintableInvoice(false);
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Handle empty date strings (if any)
    }

    const formattedDate = new Date(dateString);
    if (isNaN(formattedDate)) {
      return dateString; // Return the original string if it's not a valid date
    }

    return formattedDate.toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
  };
  const handleDelete = (invoiceId) => {
    // Show the confirmation dialog
    setIsConfirmationDialogOpen(true);
    // Set the selectedInvoiceId for deletion
  //  setSelectedInvoiceIdToDelete(invoiceId);
  };

  const handleConfirmation = (refundCharges) => {
    // Perform the deletion logic with the selectedInvoiceIdToDelete
    

    onDelete(selectedInvoice, refundCharges);
    
    // Reset the selectedInvoiceIdToDelete and close the dialog
    //setSelectedInvoiceIdToDelete(null);
    setIsConfirmationDialogOpen(false);
  };
  // Function to filter invoices based on search term
  const filteredInvoices = invoices.filter((invoice) =>
    invoice.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    invoice.InvoiceNo?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="search-bar">
        <input style={{ width: '20%', marginLeft: '15%' }}
          type="text"
          placeholder="Search by Customer or Invoice No"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <TableContainer component={Paper} style={{ width: '70%', marginLeft: '15%' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Invoice No</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Total Purchased Price</TableCell>
              <TableCell>Total Sales Price</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices.map((invoice, index) => (
              <TableRow key={invoice._id}
                style={selectedRowIndex === index ? highlightedRowStyle : {}} onClick={() => {
                  handlePrintClick(invoice);
                  setSelectedRowIndex(index);
                }}>
                <TableCell>{startIndex + index + 1}</TableCell>
                <TableCell>{invoice.InvoiceNo}</TableCell>
                <TableCell>{invoice.customerName}</TableCell>
                <TableCell>{invoice.TotalPurchasedPrice}</TableCell>
                <TableCell>{invoice.TotalSalesPrice}</TableCell>
                <TableCell>{invoice.Cash}</TableCell>
                <TableCell>{formatDate(invoice.date)}</TableCell>
                <TableCell>
                {!selectedInvoice && (
                  <Button
                    variant="contained"
                    color="primary"

                    style={{ width: "20%", marginLeft: "3%" }}
                  >
                    Print
                  </Button>)}
                  {selectedInvoice && (
                    <ReactToPrint onClick={() => handlePrintClick(invoice)}
                      trigger={() => (
                        <Button
                          variant="contained"
                          style={{ width: "20%", marginLeft: "3%" }}
                          color="primary"
                          onClick={() => handlePrintClick(invoice)}
                        >
                          Print
                        </Button>
                      )}
                      content={() => componentRef.current} // Reference to the PrintableInvoice component
                    />)}
                  <div style={{ display: "none" }}>
                    <PrintableInvoice invoice={selectedInvoice} ref={componentRef} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>

      {selectedInvoice && (
        <TableContainer component={Paper} style={{ width: '39%', marginLeft: '30%' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Purchased</TableCell>
                <TableCell>Sales</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedInvoice.orderItems.map((invoice, index) => (
                <TableRow key={invoice._id} >
                  <TableCell>{startIndex + index + 1}</TableCell>
                  <TableCell>{invoice.service}</TableCell>
                  <TableCell>{invoice.Vendor}</TableCell>
                  <TableCell>{invoice.Description}</TableCell>
                  <TableCell>{invoice.Purchased}</TableCell>
                  <TableCell>{invoice.Sales}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: "20%", marginLeft: "3%" }}
                      onClick={() => {
                        handleDelete(invoice);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>)}

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onDelete={handleConfirmation}
      />
    </div>
  );
};

export default RInvoiceTable;
