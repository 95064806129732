import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { toast } from 'react-toastify';
import { Container, Box, TextField, Grid, Button, CircularProgress, Typography, Paper } from '@mui/material';
import LoadingSpinner from './LoadingSpinner';

// Import your logo image here
import Logo from '../image/logo.png';

const UserLogin = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/users/login', {
        email,
        password,
      });

      const data = response.data;
      const { user, token } = response.data;
      localStorage.setItem('token', token);

      if (data) {
        onLogin(user);
        toast.success('Login successfully!');
      } else {
        toast.error('Login failed!', user.message);
        console.log('Login failed:', user.message);
      }

      setEmail('');
      setPassword('');
    } catch (error) {
      toast.error('Login failed! : Please Enter Correct Email and Password');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm" >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="20vh" // Set height to 20% of the viewport height
        boxShadow={3}
        p={3}
        bgcolor="white"
        style={{height:'20%', marginTop:'30%'}}
      >
        {/* Logo */}
        <img src={Logo} alt="Logo" style={{ width: '50%', marginBottom: '16px' }} />

        <Typography variant="h6" gutterBottom>
          {/* Login  */}
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={handlePasswordChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Login
              </Button>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default UserLogin;
