import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import ReactPaginate from 'react-paginate';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument'; import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import axios from 'axios';
import { CheckBox } from '@mui/icons-material';
// npm install @mui/styles // You'll need to create this component
const servicesList = [
  'Ticket Sales',
  'Visa Assistance',
  'Hotel Bookings',
  'Travel Insurance',
  'International Drivers\' License',
  'Tourism Services',
  'Religious Tours',
  'Transfer Services',
  'Cruises',
  'Discover Qatar',
  'Others',
  'Medical',
  'Rent Car',
  'Insurance'
];
function ReportingScreen() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [service, setService] = useState('');
  const [userId, setUserId] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceCurrentPage, setInvoiceCurrentPage] = useState(0);
  const [orderItemCurrentPage, setOrderItemCurrentPage] = useState(0);
  const [invoiceData, setInvoiceData] = useState([]);
  const [orderItemData, setOrderItemData] = useState([]);
  const [users, setUsers] = useState([]); // to store the list of users
  const [selectedUser, setSelectedUser] = useState(''); // to store the selected user
  const [selectedService, setSelectedService] = useState(''); // to store the selected service
  const [services, setServices] = useState(servicesList); const [unpaidChecked, setUnpaidChecked] = useState(false);
  const [totalDuesChecked, setTotalDuesChecked] = useState(false);
  const [serviceChargesChecked, setServiceChargesChecked] = useState(false);


  const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
  const PER_PAGE = 10;

  const handleInvoicePageClick = (selectedPage) => {
    setInvoiceCurrentPage(selectedPage.selected);
  };

  const handleOrderItemPageClick = (selectedPage) => {
    setOrderItemCurrentPage(selectedPage.selected);
  };
  useEffect(() => {
    axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/users')
      .then((response) => {

        const uniqueUsers = new Set();

        response.data.forEach((user) => {
          uniqueUsers.add(user);
        });
        const usersArray = Array.from(uniqueUsers);
        setUsers(usersArray);
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);
  const handleGenerateReport = async () => {
    setLoading(true);
    try {
      //setUserId(logedInUser._id);

      const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/data', {
        params: { startDate, endDate, service, userId,totalDuesChecked,serviceChargesChecked },
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      setInvoiceData(response.data.invoices);
      setOrderItemData(response.data.orderItems);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };
  const downloadData = () => {
    const csvData = convertDataToCSV(invoiceData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const handleDownloadCSV = () => {
    if (invoiceData && invoiceData.length > 0) {
      downloadData();
    } else {
      // Handle the case when there is no data to download
      console.error('No data available for download.');
    }
  };
  const convertDataToCSV = (data) => {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map((row) => Object.values(row).join(',')).join('\n');
    return header + rows;
  };
  const handleUnpaidChange = () => {
    setUnpaidChecked(!unpaidChecked);
  };

  const handleTotalDuesChange = () => {
    setTotalDuesChecked(!totalDuesChecked);
  };

  const handleServiceChargesChange = () => {
    setServiceChargesChecked(!serviceChargesChecked);
  };
  const totalPurchasedSum = invoiceData.reduce((sum, invoice) => sum + invoice.TotalPurchasedPrice, 0);
  const totalSalesSum = invoiceData.reduce((sum, invoice) => sum + invoice.TotalSalesPrice, 0);

  return (
    <div style={{ marginLeft: '15%', width: '70%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Reporting Screen</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Service"
            select
            value={service}
            onChange={(e) => setService(e.target.value)}
            SelectProps={{
              native: false,
            }}
            fullWidth
          >
            <MenuItem value="">Select Service</MenuItem>
            {services.map((service) => (
              <MenuItem key={service} value={service}>
                {service}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="User"
            select
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            SelectProps={{
              native: false,
            }}
            fullWidth
          >
            <MenuItem value="">Select User</MenuItem>
            {users.map((user) => (
              <MenuItem key={user.Name} value={user.Name}>
                {user.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormGroup style={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={totalDuesChecked}
                  onChange={handleTotalDuesChange}
                  color="primary"
                />
              }
              label="Return All Dues"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={serviceChargesChecked}
                  onChange={handleServiceChargesChange}
                  color="primary"
                />
              }
              label="Return Service Charges List"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Button
          style={{width:'30%'}}
            variant="contained"
            color="primary"
            onClick={handleGenerateReport}
          >
            Generate Report
          </Button>
        </Grid>
      </Grid>
      {invoiceData.length === 0 ? (
        <p>No Data</p>
      ) : (
        <div>
          <h2>Invoices</h2>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="invoice table">
              <TableCell>InvoiceNo</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Total Purchased Price</TableCell>
              <TableCell>Total Sales Price</TableCell>
              <TableCell>Total Dues</TableCell>
              <TableCell>Total Paid</TableCell>
              <TableCell>Refund</TableCell>
              <TableCell>USER</TableCell>
              <TableCell>Date</TableCell>
              <TableBody>
                {invoiceData
                  .slice(
                    invoiceCurrentPage * PER_PAGE,
                    (invoiceCurrentPage + 1) * PER_PAGE
                  )
                  .map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell>{invoice.InvoiceNo}</TableCell>
                      <TableCell>{invoice.Cash}</TableCell>
                      <TableCell>{invoice.Vendor}</TableCell>
                      <TableCell>{invoice.customerName}</TableCell>
                      <TableCell>{invoice.TotalPurchasedPrice}</TableCell>
                      <TableCell>{invoice.TotalSalesPrice}</TableCell>
                      <TableCell>{invoice.TotalDue}</TableCell>
                      <TableCell>{invoice.TotalPaid}</TableCell>
                      <TableCell>{invoice.refund}</TableCell>
                      <TableCell>{invoice.UserName}</TableCell>
                      <TableCell>{invoice.date}</TableCell>
                    </TableRow>
                  ))}
                <TableRow className="total-row" style={{ backgroundColor: 'yellow' }}>
                  <TableCell style={{ textAlign: 'right', fontWeight: 'bold', height: '50px' }} colSpan={1}></TableCell>
                  <TableCell style={{ fontWeight: 'bold', height: '50px' }} >Total Purchased Sum:  {totalPurchasedSum.toLocaleString()}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', height: '50px', textAlign: 'left' }} colSpan={0}>Total Sales Sum: {totalSalesSum.toLocaleString()}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', height: '50px' }} colSpan={2}></TableCell>
                </TableRow>


              </TableBody>
            </Table>
          </TableContainer>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={Math.ceil(invoiceData.length / PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleInvoicePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />

          <h2>Order Items</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableCell>Description</TableCell>
              <TableCell>Purchased</TableCell>
              <TableCell>RefernceNo</TableCell>
              <TableCell>Sales</TableCell>
              <TableCell>Service Type</TableCell>
              <TableBody>
                {orderItemData
                  .slice(
                    orderItemCurrentPage * PER_PAGE,
                    (orderItemCurrentPage + 1) * PER_PAGE
                  )
                  .map((orderItem) => (
                    <TableRow key={orderItem.id}>
                      <TableCell>{orderItem.Description}</TableCell>
                      <TableCell>{orderItem.Purchased}</TableCell>
                      <TableCell>{orderItem.RefernceNo}</TableCell>
                      <TableCell>{orderItem.Sales}</TableCell>
                      <TableCell>{orderItem.service}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={Math.ceil(orderItemData.length / PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleOrderItemPageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>

      )}
      <PDFViewer width={600} height={400}>
        <PDFDocument data={data} />
      </PDFViewer>
      <br></br>
      <br></br>
      <br></br>
      <PDFDownloadLink document={<PDFDocument data={data} />} fileName="report.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now PDF!')}
      </PDFDownloadLink>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '15%', marginLeft: '2%' }}
        onClick={handleDownloadCSV}
      >
        Download Excel
      </Button>
    </div>
  );
}
export default ReportingScreen;
