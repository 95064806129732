import React, { useState, useEffect } from "react";
import InvoiceTable from './InvoiceTable';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataDisplay  from "./DataDisplay";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false); // State for delete success
  const token = localStorage.getItem('token');
  const apiUrl = "https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices";
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items per page
  const [loading, setLoading] = useState(true); 
  
  const [dayData, setDayData] = useState({});
  const [monthData, setMonthData] = useState({});
  const [yearData, setYearData] = useState({});

  useEffect(() => {
    fetchInvoices();
  }, []);
  useEffect(() => {
    // Make a GET request to your API endpoint
    axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/datas')
      .then((response) => {
        const { dayResults, monthResults, yearResults } = response.data;
        setDayData(dayResults[0] || {});
        setMonthData(monthResults[0] || {});
        setYearData(yearResults[0] || {});
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const fetchInvoices = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      });
      setInvoices(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setLoading(false);
    }
  };
  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const handleDelete = async (invoiceId) => {
    try {
      await axios.delete(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/${invoiceId}`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      });

      // Remove the deleted invoice from the list
      setInvoices((prevInvoices) => prevInvoices.filter((invoice) => invoice._id !== invoiceId));

      // Show delete success message
      setDeleteSuccess(true);
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const handleSnackbarClose = () => {
    // Close the delete success Snackbar
    setDeleteSuccess(false);
  };

  return (
    <div>
         <DataDisplay dayData={dayData} monthData={monthData} yearData={yearData}  />

      {loading ? (
        <CircularProgress size={100} style={{ margin: "auto" }} />
      ) : (
        <>
          {invoices.length === 0 ? (
            <p>No data available</p>
          ) : (
            <InvoiceTable
              invoices={invoices}
              onDelete={handleDelete}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}

      {/* Delete success Snackbar */}
      <Snackbar open={deleteSuccess} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleSnackbarClose}>
          Record deleted successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InvoiceList;
