import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import axios from "axios";

function GenericServiceForm({ service, onServiceChange }) {
  const [airlines, setAirlines] = useState([]);
  const [vendorList, setVendor] = useState([]);
  const [formData, setFormData] = useState({
    Purchased: '',
    Sales: '',
    Description: '',
    RefernceNo: '',
    Vendor: '',
    airliness: '',
    PassengerName:'',
    BookingRefernce:'',
  });

  useEffect(() => {
    fetchAirlines();
    fetchVendor();
  }, []);

  const fetchAirlines = async () => {
    try {
      const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/airlines');
      setAirlines(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchVendor = async () => {
    try {
        const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/vendor');
        setVendor(response.data);
    } catch (error) {
        console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    onServiceChange(service, formData);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <h1>{service} Form</h1>
      {service === "Ticket Sales" && (
        <FormControl fullWidth style={{marginLeft:'2%'}}>
          <InputLabel>Airlines</InputLabel>
          <Select
            value={formData.airliness}
            name='airliness' required
            onChange={handleInputChange}
          >
            {airlines.map((airline) => (
              <MenuItem key={airline.Name} value={airline.Name}>
                {airline.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
       <br></br>
      <FormControl fullWidth style={{marginLeft:'2%'}}>
        <InputLabel>Select Vendor</InputLabel>
        <Select
          value={formData.Vendor}
          name='Vendor' required
          onChange={handleInputChange}
        >
          {vendorList.map((vendor) => (
            <MenuItem key={vendor.Name} value={vendor.Name}>
              {vendor.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        type="number"
        name="Purchased"
        label="Purchased" required
        value={formData.Purchased}
        onChange={handleInputChange}
        inputProps={{
          min: 0,
        }}
      />

      <TextField
        type="number"
        name="Sales"
        label="Sales" required
        value={formData.Sales}
        onChange={handleInputChange}
        inputProps={{
          min: 0,
        }}
      />
       <TextField
        type="text"
        name="PassengerName"
        label="Passenger Name" required
        value={formData.PassengerName}
        onChange={handleInputChange}
      />
       <TextField
        type="text"
        name="BookingRefernce"
        label="Booking Refernce" required
        value={formData.BookingRefernce}
        onChange={handleInputChange}
      />
      <TextField
        type="text"
        name="Description"
        label="Description"
        multiline
        maxRows={4}
        required
        value={formData.Description}
        onChange={handleInputChange}
      />
      <TextField
        label="ReferenceNo"
        name="RefernceNo"
        value={formData.RefernceNo}
        onChange={handleInputChange}
      />
    </Box>
  );
}

export default GenericServiceForm;
