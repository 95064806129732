import React, { useState, useEffect } from "react";
import RInvoiceTable from "./ReceInvoiceTable";
import RecevablesTable from "./RecevablesTable";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


const ReceviableFor = () => {
  const [invoices, setInvoices] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [recevables, setRecevables] = useState([]);
  const [recevablesPage, setRecevablesPage] = useState(1);

  const token = localStorage.getItem("token");
  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const apiUrl = "https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices";
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchInvoices();
  }, []);
  useEffect(() => {
    // Fetch data from your backend API
    fetch('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/recevables')
      .then((response) => response.json())
      .then((data) => setRecevables(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setInvoices(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (invoice) => {
    const { Purchased, Sales, InvoiceId, _id } = invoice;
    try {
      // Step 1: Delete the order item
      await axios.delete(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/orderItems/${_id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      // Step 2: Fetch the invoice details
      const response = await axios.get(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/${InvoiceId}`);
      const { TotalPurchasedPrice, TotalSalesPrice, customerName, Cash, InvoiceNo } = response.data;

      // Step 3: Calculate updated prices
      const newTotalPurchasedPrice = TotalPurchasedPrice - Purchased;
      const newSalesPrice = TotalSalesPrice - Sales;

      // Step 4: Update the invoice
      const updatedData = {
        TotalPurchasedPrice: newTotalPurchasedPrice,
        TotalSalesPrice: newSalesPrice,
        _id: InvoiceId,
        customerName,
        Cash,
        date: new Date(),
        UserName: logedInUser.Name,
        InvoiceNo,
      };

      await axios.put(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/${InvoiceId}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      // Step 5: Create a refundable entry
      const data = {
        CustomerName: customerName,
        Amount: Sales,
        CurrentDate: new Date(),
        prevDate: response.data.date,
        userName: logedInUser.Name,
        invoiceNo: InvoiceNo,
      };

      await axios.post("https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/recevables", data, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      setDeleteSuccess(true);
    } catch (error) {
      console.error("Error deleting invoice:", error);
      // Handle errors here, e.g., show an error message to the user
    }
  };
  const updateInvoiceData = (updatedData) => {
    fetchInvoices();
  // setInvoices(updatedData);
  };
  const handleSnackbarClose = () => {
    setDeleteSuccess(false);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress size={100} style={{ margin: "auto" }} />
      ) : (
        <>
          {invoices.length === 0 ? (
            <p>No data available</p>
          ) : (
            <RInvoiceTable
              invoices={invoices}
              onDelete={handleDelete}
              currentPage={currentPage}
              onUpdateData={updateInvoiceData}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
      <br></br>
        <br></br>
      <RecevablesTable
        recevables={recevables}
        currentPage={recevablesPage}
        itemsPerPage={itemsPerPage}
        onPageChange={(newPage) => setRecevablesPage(newPage)}
      />
      <Snackbar open={deleteSuccess} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleSnackbarClose}>
          Record deleted successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ReceviableFor;
