import { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import {
  faPlus, faCog, faUsers, faFileInvoiceDollar, faSignOutAlt, faEdit, faChartBar, faPlane, faWrench,
  faMoneyBill, faMoneyCheckAlt,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvoiceList from './InvoiceList';
import ManageItem from './ManageItem';
import UserList from './UserList';
import ClassList from './ClassManagement';
import MainScreen from './MainScreen';
import ReportingScreen from './Report/ReportingScreen';
import { makeStyles } from '@mui/styles';
import logo from '../image/logo.png'
import AirlinesScreen from './AirlineList/AirlinesScreen';
import ServiceTypeForm from './ServiceTypeForm/ServiceTypeForm';
import Refund from './Refund';
import VendorForm from './Vendor/VendorForm';
import ReceviableFor from './Receviable/ReceviableFor'
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  sidebar: {
    backgroundColor: "#333", // Dark background color
    color: "white",
    width: drawerWidth,
    flexShrink: 0,
    overflowY: 'auto',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#333", // Dark background color
    color: "white",
  },
  menu: {
    paddingTop: theme.spacing = (2),
  },
  activeMenuItem: {
    backgroundColor: "#4CAF50", // Green background color for active menu item
    color: "white",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing = 2,
    backgroundColor: "white", // Darker background color for the logo container
  },
  logoImage: {
    width: "100%",
    height: "auto",
    borderRadius: '50%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing = 3,
    backgroundColor: "#f9f9f9", // Light background color for content area
  },
}));



function Dashboards({ userRole }) {
  const classes = useStyles();
  const [activeMenuItem, setActiveMenuItem] = useState('manageInvoice');

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const menuItems = [
    { label: 'Manage Invoice', icon: faFileInvoiceDollar, key: 'manageInvoice' },
    { label: 'Create Invoice', icon: faCog, key: 'manageItem' },
    { label: 'Add New AirLine', icon: faPlane, key: 'airline' },
    { label: 'Add New ServiceType', icon: faWrench, key: 'servicetype' },
    { label: 'Refund', icon: faMoneyBill, key: 'refund' },
    { label: 'Receivable', icon: faMoneyCheckAlt, key: 'receivable' },
    { label: 'Vendor', icon: faWrench, key: 'vendor' },
    { label: 'Report', icon: faChartBar, key: 'report' },
    { label: 'User Management', icon: faUsers, key: 'classList' },

  ];
  if (userRole === 'Admin') {
    menuItems.push({ label: 'User Management', icon: faUsers, key: 'classList' });
  }
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.sidebar}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.logo}>
          <img
            className={classes.logoImage}
            src={logo} // Replace with your logo's URL
            alt="Logo"
          />
        </div>
        <div className={classes.drawerContainer}>
          <List>
            {menuItems.map((menuItem) => (
              <ListItem
                button
                key={menuItem.key}
                onClick={() => handleMenuItemClick(menuItem.key)}
                className={activeMenuItem === menuItem.key ? classes.activeMenuItem : ''}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={menuItem.icon} style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary={menuItem.label} />
              </ListItem>
            ))}
            <ListItem button className="logout" onClick={handleLogout}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOutAlt} style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {activeMenuItem === 'manageItem' && <MainScreen />}
        {activeMenuItem === 'manageInvoice' && <InvoiceList />}
        {activeMenuItem === 'classList' && <UserList />}
        {activeMenuItem === 'report' && <div> <ReportingScreen /></div>}
        {activeMenuItem === 'airline' && <div> <AirlinesScreen /></div>}
        {activeMenuItem === 'servicetype' && <div> <ServiceTypeForm /></div>}
        {activeMenuItem === 'vendor' && <div> <VendorForm /></div>}
        {activeMenuItem === 'refund' && <div> <Refund /></div>}
        {activeMenuItem === 'receivable' && <div> <ReceviableFor /></div>}
      </main>
    </div>
  );
}

export default Dashboards;
