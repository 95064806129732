import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

function ConfirmationDialog({ isOpen, onClose, onDelete }) {
  const [refundCharges, setRefundCharges] = useState('');

  const handleSave = () => {
    // Call the onDelete callback with the refundCharges value
    onDelete(refundCharges);
    // Close the dialog
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Refund charges are zero. Enter a value and click "Save" to continue.
        </DialogContentText>
        <TextField
          label="Refund Charges"
          type="number"
          value={refundCharges}
          onChange={(e) => setRefundCharges(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default ConfirmationDialog;
