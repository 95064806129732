import { Page, Text, View, Document, StyleSheet,Image  } from '@react-pdf/renderer';
import { json } from 'react-router-dom';
import logoImage from '../../image/logo.png'

const PDFDocument = ({ data }) => {
  console.log("PDF" +JSON.stringify(data));
  const { invoices, orderItems } = data;
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoContainer: {
      flex: 1,
      alignItems: 'center',
    },
    logo: {
      width: 300,
      height: 50,
      marginLeft:'50%',
    },
    tableContainer: {
      marginBottom: 10,
    },
    table: {
      display: 'table',
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomColor: '#000',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
    },
    tableHeaderCell: {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
      textAlign: 'center',
      flex: 1,
      fontSize:12,
    },
    header:{
      marginLeft:'50%',
      marginTop:'5%'
    },
    tableCell: {
      textAlign: 'center',
      flex: 1,
      fontSize: 10,
    },
    totalsContainer: {
      marginTop: 10,
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        
      <Image style={styles.logo} src={logoImage} />

        <View>
          <Text style={styles.header}>Report</Text>
        </View>
          <br></br>
        {/* Invoices Table */}
        <View style={styles.tableContainer}>
          <Text>Invoices List</Text>
          
          <br></br><br></br><br></br>
          <View style={styles.table}>
            {/* Table header */}
            <View style={styles.tableRow}>
              
            <Text style={styles.tableHeaderCell}>InvoiceNo</Text>
              <Text style={styles.tableHeaderCell}>Customer Name</Text>
              <Text style={styles.tableHeaderCell}>USER</Text>
              <Text style={styles.tableHeaderCell}>Vendor</Text>
              <Text style={styles.tableHeaderCell}>Payment Type</Text>
              <Text style={styles.tableHeaderCell}>Total Purchased Price</Text>
              <Text style={styles.tableHeaderCell}>Total Sales Price</Text>
              <Text style={styles.tableHeaderCell}>Total Dues</Text>
              <Text style={styles.tableHeaderCell}>Total Paid</Text>
              <Text style={styles.tableHeaderCell}>Refund</Text>
              <Text style={styles.tableHeaderCell}>Date</Text>
            </View>

            {/* Table data */}
            {invoices?.map((invoice) => (
              <View style={styles.tableRow} key={invoice.id}>
              <Text style={styles.tableCell}>{invoice.InvoiceNo}</Text>
                <Text style={styles.tableCell}>{invoice.customerName}</Text>
                <Text style={styles.tableCell}>{invoice.UserName}</Text>
                <Text style={styles.tableCell}>{invoice.Vendor}</Text>
                <Text style={styles.tableCell}>{invoice.Cash}</Text>
                <Text style={styles.tableCell}>{invoice.TotalPurchasedPrice}</Text>
                <Text style={styles.tableCell}>{invoice.TotalSalesPrice}</Text>
                <Text style={styles.tableCell}>{invoice.TotalDue}</Text>
                <Text style={styles.tableCell}>{invoice.TotalPaid}</Text>
                <Text style={styles.tableCell}>{invoice.refund}</Text>
                <Text style={styles.tableCell}>{invoice.date}</Text>
              </View>
            ))}
            {/* Total row */}
            <View style={styles.totalRow}>
              <Text style={styles.totalCell} colSpan={3}></Text>
              <Text style={{marginLeft:'50%', fontWeight:'bold',fontSize:'12px'}}>Total Purchased Sum: {calculateTotalPurchased(invoices)}</Text>
              <Text  style={{marginLeft:'50%', fontWeight:'bold',fontSize:'12px'}}>Total Sales Sum: {calculateTotalSales(invoices)}</Text>
              <Text style={styles.totalCell} colSpan={3}></Text>
            </View>
          </View>
        </View>
        <br></br><br></br><br></br>
        {/* Order Items Table */}
        <View style={styles.tableContainer}>
          <Text>Order Items</Text>
          <View style={styles.table}>
            {/* Table header */}
            <View style={styles.tableRow}>
              
            <Text style={styles.tableHeaderCell}>Service Type</Text>
              <Text style={styles.tableHeaderCell}>Description</Text>
              <Text style={styles.tableHeaderCell}>Purchased</Text>
              <Text style={styles.tableHeaderCell}>ReferenceNo</Text>
              <Text style={styles.tableHeaderCell}>Sales</Text>
            </View>

            {/* Table data */}
            {orderItems?.map((orderItem) => (
              <View style={styles.tableRow} key={orderItem.id}>
                
                <Text style={styles.tableCell}>{orderItem.service}</Text>
                <Text style={styles.tableCell}>{orderItem.Description}</Text>
                <Text style={styles.tableCell}>{orderItem.Purchased}</Text>
                <Text style={styles.tableCell}>{orderItem.ReferenceNo}</Text>
                <Text style={styles.tableCell}>{orderItem.Sales}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

// Helper function to calculate the total purchased sum
const calculateTotalPurchased = (invoices) => {
  return invoices?.reduce((total, invoice) => total + invoice.TotalPurchasedPrice, 0).toLocaleString();
};

// Helper function to calculate the total sales sum
const calculateTotalSales = (invoices) => {
  return invoices?.reduce((total, invoice) => total + invoice.TotalSalesPrice, 0).toLocaleString();
};

export default PDFDocument;