import React, { useState, useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PrintableInvoice from "../PrintableInvoice";
import ReactToPrint from "react-to-print";
import Pagination from "@mui/material/Pagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider, TextField, FormControl, InputLabel } from '@mui/material';
const ReceInvoiceTable = ({
  invoices,
  onDelete,
  currentPage,
  itemsPerPage,
  onPageChange,
  onUpdateData,
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedInvoices = invoices.slice(startIndex, endIndex);
  const [showPrintableInvoice, setShowPrintableInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const componentRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalDue, setTotalDue] = useState(0);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [invoiceData, setInvoiceData] = useState(invoices);


  const totalPages = Math.ceil(invoices.length / itemsPerPage);
  const highlightedRowStyle = {
    backgroundColor: 'lightblue', // Change the background color to your desired highlight color
  };
  const handlePrintClick = (invoice) => {
    console.log("Selected Invoice ID:", invoice._id);
    setSelectedInvoice(invoice);
    setShowPrintableInvoice(true);
    setTotalPaid(invoice.TotalPaid);
    setTotalDue(invoice.TotalDue);
    setTotalSalePrice(invoice.TotalSalesPrice);
  };

  const handleHidePrintableInvoice = () => {
    setShowPrintableInvoice(false);
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Handle empty date strings (if any)
    }

    const formattedDate = new Date(dateString);
    if (isNaN(formattedDate)) {
      return dateString; // Return the original string if it's not a valid date
    }

    return formattedDate.toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
  };
  
  const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
  const UpdateRecord = async () => {
    try {
      const apiUrl = "https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/recevables";
      const response = await axios.post(apiUrl, {
        CustomerName: selectedInvoice.customerName,
        Amount: totalPaid,
        date: new Date().toISOString().slice(0, 10),
        userName: logedInUser.Name,
        invoiceNo: selectedInvoice.InvoiceNo,
      });

      if (response.data) {
        toast.success('Successfully saved', {
          position: 'top-right',
          autoClose: 3000, // Close the notification after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      const InvoiceId=selectedInvoice._id;
      const prvPaid=Number(selectedInvoice.TotalPaid);

      const updatedData = {
        TotalDue:totalDue,
        TotalPaid:Number(totalPaid)+Number(prvPaid),
        date: new Date(),
      };

      const responses= await axios.put(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/${InvoiceId}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          // authorization: `Bearer ${token}`,
        },
      });
      const updatedInvoiceData = invoiceData.map((invoice) => {
        if (invoice._id === selectedInvoice._id) {
          return {
            ...invoice,
            TotalDue: totalDue,
            TotalPaid: Number(totalPaid) + Number(prvPaid),
            date: new Date(),
          };
        }
        return invoice;
      });
      onUpdateData(updatedInvoiceData);
       setSelectedInvoice(null);
       
    } catch (error) {
      toast.error('Error occurred while saving data', {
        position: 'top-right',
        autoClose: 3000, // Close the notification after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
  // Function to filter invoices based on search term
  const filteredInvoices = invoices.filter((invoice) =>
    invoice.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    invoice.InvoiceNo?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const calcualteTotal = (e, invoice) => {
    const value = e.target.value;
    let total = invoice.TotalDue;
    const dues = total - value;
    setTotalDue(dues);
  }
  return (
    <div>
      <div className="search-bar">
        <input style={{ width: '20%', marginLeft: '15%' }}
          type="text"
          placeholder="Search by Customer or Invoice No"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <TableContainer component={Paper} style={{ width: '70%', marginLeft: '15%' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Invoice No</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Total Purchased Price</TableCell>
              <TableCell>Total Sales Price</TableCell>

              <TableCell>Total Dues</TableCell>
              <TableCell>Total Paid</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices.map((invoice, index) => (
              <TableRow key={invoice._id}
                style={selectedRowIndex === index ? highlightedRowStyle : {}} onClick={() => {
                  handlePrintClick(invoice);
                  setSelectedRowIndex(index);
                }}>
                <TableCell>{startIndex + index + 1}</TableCell>
                <TableCell>{invoice.InvoiceNo}</TableCell>
                <TableCell>{invoice.customerName}</TableCell>
                <TableCell>{invoice.TotalPurchasedPrice}</TableCell>
                <TableCell>{invoice.TotalSalesPrice}</TableCell>
                <TableCell>{invoice.TotalDue}</TableCell>
                <TableCell>{invoice.TotalPaid}</TableCell>
                <TableCell>{invoice.Cash}</TableCell>
                <TableCell>{formatDate(invoice.date)}</TableCell>
                <TableCell>
                {!selectedInvoice && (<Button
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", marginLeft: "3%" }}
                  >
                    print
                  </Button>)}
                  {selectedInvoice && (
                    <ReactToPrint onClick={() => handlePrintClick(invoice)}
                      trigger={() => (
                        <Button
                          variant="contained"
                          style={{ width: "20%", marginLeft: "3%" }}
                          color="primary"
                          onClick={() => handlePrintClick(invoice)}
                        >
                          Print
                        </Button>
                      )}
                      content={() => componentRef.current} // Reference to the PrintableInvoice component
                    />)}
                  <div style={{ display: "none" }}>
                    <PrintableInvoice invoice={selectedInvoice} ref={componentRef} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>

      {selectedInvoice && (
        <TableContainer component={Paper} style={{ width: '43%', marginLeft: '30%' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Purchased</TableCell>
                <TableCell>Sales</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedInvoice.orderItems.map((invoice, index) => (
                <TableRow key={invoice._id} >
                  <TableCell>{startIndex + index + 1}</TableCell>
                  <TableCell>{invoice.service}</TableCell>
                  <TableCell>{invoice.Vendor}</TableCell>
                  <TableCell>{invoice.Description}</TableCell>
                  <TableCell>{invoice.Purchased}</TableCell>
                  <TableCell>{invoice.Sales}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={4}></TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>{totalSalePrice}</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {/* Add row for Amount Paid */}
              <TableRow>
                <TableCell colSpan={4}></TableCell>
                <TableCell>Amount Paid</TableCell>
                <TableCell><TextField name='due' type='number' value={totalPaid} disabled={selectedInvoice?.TotalDue === 0} onChange={(e) => {
                  setTotalPaid(e.target.value); calcualteTotal(e, selectedInvoice);
                }} ></TextField></TableCell> {/* You need to define amountPaid */}
                <TableCell></TableCell>
              </TableRow>
              {/* Add row for Balance Due */}
              <TableRow>
                <TableCell colSpan={4}></TableCell>
                <TableCell>Balance Due</TableCell>
                <TableCell>{totalDue}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>)}

        
      <Button
        variant="contained"
        color="secondary"
        style={{ width: "10%", marginLeft: "3%", marginTop: '2%' }}  disabled={selectedInvoice?.TotalDue === 0} onClick={(e) => UpdateRecord(e)}
      >
        update
      </Button>
    </div>
  );
};

export default ReceInvoiceTable;
