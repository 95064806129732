import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Define your custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  registrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '300px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px #ccc',
  },
  formControl: {
    width: '100%',
    marginBottom: '10px',
  },
  submitButton: {
    width: '100%',
  },
}));

const UserRegistration = ({ onRegistration, userRole }) => {
  const classes = useStyles(); // Initialize the custom styles
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('User');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const lowercaseUsername = e.target.value.toLowerCase();
    setEmail(lowercaseUsername);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/users', {
        Name: name,
        Email: email,
        Password: password,
        Role: selectedRole,
      });

      const data = response.data;
      console.log(data); // Check the response from the server

      if (data) {
        // Call the onRegistration function passed as a prop from the parent component (App.js)
        onRegistration(data.message);
        toast.success('Registration successfully!'); // Assuming the server returns a success message after registration
      } else {
        // Handle registration failurec
        toast.error('Registration failed:', data.message);
        console.log('Registration failed:', data.message);
      }

      // Reset the form after handling the data
      setName('');
      setEmail('');
      setPassword('');

      setSelectedRole('User');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return userRole === 'Admin' ? (
    <div className={classes.registrationContainer}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          type="text"
          value={name}
          onChange={handleNameChange}
          required
          className={classes.formControl}
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
          className={classes.formControl}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          required
          className={classes.formControl}
        />
        <FormControl className={classes.formControl}>
          <InputLabel>Role</InputLabel>
          <Select
            value={selectedRole}
            onChange={handleRoleChange}
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="User">User</MenuItem>
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          Register
        </Button>
      </form>
    </div>
  ) : null;
};

export default UserRegistration;
