import React, { useState, useEffect } from "react";
import RInvoiceTable from "./RInvoiceTable";
import RInvoiceTables from "./RefundTableCharges";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ConfirmationDialog from "./ConfirmationDialog"

const Refund = () => {
  const [invoices, setInvoices] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");
  const logedInUser = JSON.parse(localStorage.getItem("logedInUser"));
  const apiUrl = "https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices";
  const itemsPerPage = 10; const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [refundCharges, setRefundCharges] = useState(0);
  const [recevables, setRecevables] = useState([]);
  const [recevablesPage, setRecevablesPage] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setInvoices(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    // Fetch data from your backend API
    fetch('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/refundables')
      .then((response) => response.json())
      .then((data) => setRecevables(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const refundData=()=>{
    fetch('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/refundables')
    .then((response) => response.json())
    .then((data) => setRecevables(data))
    .catch((error) => console.error('Error fetching data:', error));
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (invoice, refundCharges) => {
    setLoading(true);
    const { Purchased, Sales, InvoiceId, _id } = invoice;
    try {
      // Step 1: Delete the order item
      await axios.delete(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/orderItems/${invoice.orderItems[0]._id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      // Step 2: Fetch the invoice details
      const response = await axios.get(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/${_id}`);
      const { TotalPurchasedPrice, TotalSalesPrice, customerName, Cash, InvoiceNo } = response.data;

      // Step 3: Calculate updated prices
      const newTotalPurchasedPrice = TotalPurchasedPrice -  Number(invoice.orderItems[0].Purchased);
      const newSalesPrice = TotalSalesPrice - Number(invoice.orderItems[0].Sales);
      let newTotalPaid=0;
      if(refundCharges){
        newTotalPaid= response.data.TotalPaid -Number(invoice.orderItems[0].Sales);
        newTotalPaid=newTotalPaid+Number(refundCharges);
      }else{newTotalPaid= response.data.TotalPaid -Number(invoice.orderItems[0].Sales);}
       
      // Step 4: Update the invoice
      const updatedData = {
        TotalPurchasedPrice: newTotalPurchasedPrice,
        TotalSalesPrice: newSalesPrice,
        date: new Date(),
        UserName: logedInUser.Name,
        refund: refundCharges,
        TotalPaid:newTotalPaid,
      };

      await axios.put(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/invoices/${_id}`, updatedData, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      // Step 5: Create a refundable entry
      const data = {
        CustomerName: customerName,
        Amount:  Number(invoice.orderItems[0].Sales),
        Charges:   Number(refundCharges),
        CurrentDate: new Date(),
        prevDate: response.data.date,
        userName: logedInUser.Name,
        invoiceNo: response.data.InvoiceNo,
      };

      await axios.post("https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/refundables", data, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      fetchInvoices();
      setDeleteSuccess(true);
      refundData();
    } catch (error) {
      console.error("Error deleting invoice:", error);
      setLoading(false);
    } finally {
      // Set isLoading back to false when the operation is complete
      setLoading(false);
    }
  };
  const handleChildRefundChargesChange = (newValue) => {
    setRefundCharges(newValue);
  };
  const handleSnackbarClose = () => {
    setDeleteSuccess(false);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress size={100} style={{ margin: "auto" }} />
      ) : (
        <>
          {invoices.length === 0 ? (
            <p>No data available</p>
          ) : (
            <RInvoiceTable
              invoices={invoices}
              onDelete={handleDelete}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              onRefundChargesChange={handleChildRefundChargesChange}
            />
          )}
        </>
      )}
      <br></br>
      <br></br>
      {recevables.length > 0 ? (
        <RInvoiceTables
          recevables={recevables}
          currentPage={recevablesPage}
          itemsPerPage={itemsPerPage}
          onPageChange={(newPage) => setRecevablesPage(newPage)}
        />
      ) : (
        <p>No recevables to display</p>
      )}
      <Snackbar open={deleteSuccess} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleSnackbarClose}>
          Record deleted successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Refund;
