import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress, // Added CircularProgress for loader
} from '@mui/material';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';

const AirlinesScreen = () => {
  const [airlines, setAirlines] = useState([]);
  const [newAirlineName, setNewAirlineName] = useState('');
  const [editAirlineName, setEditAirlineName] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false); // State for loader
  const [searchTerm, setSearchTerm] = useState(''); // State for search

  useEffect(() => {
    fetchAirlines();
  }, [currentPage]);

  const fetchAirlines = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/airlines');
      setAirlines(response.data);
      setLoading(false); // Stop loading
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while fetching airlines");
      setLoading(false); // Stop loading on error
    }
  };

  const handleCreateAirline = async () => {
    try {
      await axios.post('https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/airlines', { Name: newAirlineName });
      setNewAirlineName('');
      fetchAirlines();
      toast.success("Saved Successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while creating an airline");
    }
  };

  const handleEditAirline = async () => {
    try {
      await axios.put(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/airlines/${editingId}`, { Name: editAirlineName });
      setEditingId(null);
      setEditAirlineName('');
      fetchAirlines();
      toast.success("Updated Successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while updating the airline");
    }
  };

  const handleDeleteAirline = async (id) => {
    try {
      await axios.delete(`https://backend-goeesy8e3-mtayyeb021-gmailcom.vercel.app/api/airlines/${id}`);
      fetchAirlines();
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while deleting the airline");
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page when searching
  };

  // Pagination
// Filter the data based on the search term

const filteredAirlines = airlines.filter((airline) =>
airline.Name.toLowerCase().includes(searchTerm.toLowerCase())
);

// Calculate the indices for the pagination
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentAirlines = filteredAirlines.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div>
      <h2>Airlines</h2>

     
      <br></br>
            <hr></hr>
            <br></br>

      <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: '32%' }}>
        <TextField
          label="New Airline Name"
          variant="outlined"
          value={newAirlineName}
          onChange={(e) => setNewAirlineName(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ width: '11%', marginLeft: '11%' }}
          onClick={() => {
            if (newAirlineName.trim() !== '') {
              handleCreateAirline();
            } else {
              toast.error("Airline Name cannot be empty");
            }
          }}
        >
          Create
        </Button>
      </div>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: '32%' }}>
        <TextField
         style={{width:'47%'}}
          label="Search Airline By Name"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      
      <br></br>
            <hr></hr>
            <br></br>
      {loading ? ( // Display loader while loading data
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper} style={{ width: '30%', marginLeft: '30%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentAirlines.map((airline) => (
                <TableRow key={airline._id}>
                  <TableCell>
                    {editingId === airline._id ? (
                      <TextField
                        value={editAirlineName}
                        onChange={(e) => setEditAirlineName(e.target.value)}
                      />
                    ) : (
                      airline.Name
                    )}
                  </TableCell>
                  <TableCell>
                    {editingId === airline._id ? (
                      <Button variant="contained" color="primary" onClick={handleEditAirline}>
                        Save
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: '15%', marginLeft: '4%' }}
                          onClick={() => {
                            setEditingId(airline._id);
                            setEditAirlineName(airline.Name);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ width: '15%', marginLeft: '4%' }}
                          onClick={() => handleDeleteAirline(airline._id)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Pagination
          count={(filteredAirlines.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>

      <ToastContainer />
    </div>
  );
};

export default AirlinesScreen;
