import React from 'react';
import { Container, Typography, Paper, Grid } from '@mui/material';

const DataDisplay = ({ dayData, monthData, yearData }) => {
    return (
        <Container maxWidth="md" style={{ marginTop: '20px', textAlign: 'left' }}>
            <Grid container spacing={3} >
                <Grid item xs={4} >
                    <Paper elevation={3} style={{ padding: '20px', color: 'white', backgroundColor: 'green' }}>
                        <Typography variant="h6" gutterBottom>
                            Day Data
                        </Typography>
                        <hr></hr>           
                         <Typography variant="body1">
                            Total Purchased: {Number(dayData.totalPurchased).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            Total Sales: {Number(dayData.totalSales).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            Total Refund Charges: {Number(dayData.totalRefund).toLocaleString()}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '20px', color: 'white', backgroundColor: 'green' }}>
                        <Typography variant="h6" gutterBottom>
                            Month Data
                        </Typography>
                        <hr></hr>
                        <Typography variant="body1">
                            Total Purchased: {Number(monthData.totalPurchased).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            Total Sales: {Number(monthData.totalSales).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            Total Refund Charges: {Number(monthData.totalRefund).toLocaleString()}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '20px', color: 'white', backgroundColor: 'green' }}>
                        <Typography variant="h6" gutterBottom>
                            Year Data
                        </Typography>
                        <hr></hr>
                        <Typography variant="body1">
                            Total Purchased: {Number(yearData.totalPurchase).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            Total Sales: {Number(yearData.totalSales).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            Total Refund Charges: {Number(yearData.totalRefund).toLocaleString()}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <br></br>
        </Container>
    );
};

export default DataDisplay;
