import React, { useState, useEffect } from 'react';

import "./PrintableInvoice.css";
import logo from './logo.png'

import useArabicNumber from "./useArabicNumber";
import numberToWords from 'number-to-words';
import numberToArabicWords from 'number-to-arabic-words';
import { red } from '@mui/material/colors';
function reverseString(num) {
  const reversedNum = Number(String(num).split('').reverse().join(''));
  return reversedNum;
}
function swapLastTwoWords(text) {
  const words = text.split(" ");

  if (words.length < 2) {
    return text; // Return the original text if there are less than two words
  }

  const lastWord = words.pop(); // Remove the last word
  const secondLastWord = words.pop(); // Remove the second-to-last word

  words.push(lastWord); // Add the last word as the second-to-last word
  words.push(secondLastWord); // Add the second-to-last word as the last word

  return words.join(" ");
}

function convertNumberToArabicWords(number) {
  const units = ["", "ألف", "مليون", "مليار", "تريليون", "كوادريليون", "كوينتليون"];
  const words = ["صفر", "واحد", "اثنان", "ثلاثة", "أربعة", "خمسة", "ستة", "سبعة", "ثمانية", "تسعة"];
  const handard = ["", "مائة", "مائتين", "ثلاثمائه", "أربعمائة", "خمسمائة", "ستمائة", "سبعمائة", "ثمانمائة", "تسعمائة"]
  const tensWords = ["", "", "عشرون", "ثلاثون", "أربعون", "خمسون", "ستون", "سبعون", "ثمانون", "تسعون"];
  const teensWords = ["عشرة", "أحد عشر", "اثنا عشر", "ثلاثة عشر", "أربعة عشر", "خمسة عشر", "ستة عشر", "سبعة عشر", "ثمانية عشر", "تسعة عشر"];


  if (number < 10) {
    return words[number];
  } else if (number < 20) {
    return teensWords[number - 10];
  } else if (number < 100) {
    const re = reverseString(number);
    return tensWords[Math.floor(number / 10)] + (number % 10 !== 0 ? " " + words[number % 10] : "");
  } else if (number < 1000) {
    return handard[Math.floor(number / 100)] + " " + (number % 100 !== 0 ? " " + convertNumberToArabicWords(number % 100) : "");
  } else {
    let unitIndex = 0;
    let result = "";

    while (number > 0) {
      const triplet = number % 1000;

      if (triplet > 0) {
        const tripletWords = convertNumberToArabicWords(triplet);
        result = tripletWords + (unitIndex > 0 ? " " + units[unitIndex] : "") + (result ? " ," + result : "");
      }

      number = Math.floor(number / 1000);
      unitIndex++;
    }

    return result;
  }
}

function convertNumberToWords(number) {
  const units = ["", "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion"];
  const words = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const tensWords = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const teensWords = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

  if (number < 10) {
    return words[number];
  } else if (number < 20) {
    return teensWords[number - 10];
  } else if (number < 100) {
    return tensWords[Math.floor(number / 10)] + (number % 10 !== 0 ? " " + words[number % 10] : "");
  } else if (number < 1000) {
    return words[Math.floor(number / 100)] + " Hundred" + (number % 100 !== 0 ? " " + convertNumberToWords(number % 100) : "");
  } else {
    let unitIndex = 0;
    let result = "";

    while (number > 0) {
      const triplet = number % 1000;

      if (triplet > 0) {
        const tripletWords = convertNumberToWords(triplet);
        result = tripletWords + (unitIndex > 0 ? " " + units[unitIndex] : "") + (result ? ", " + result : "");
      }

      number = Math.floor(number / 1000);
      unitIndex++;
    }

    return result;
  }
}
function convertDecimalToWords(decimal) {

  // Limit the decimal part to two digits
  const roundedDecimal = Math.round(decimal * 100);
  if (roundedDecimal === 0) {
    return "";
  } else {
    return "and " + convertNumberToWords(roundedDecimal) + " Dirhams";
  }
}
function convertDecimalToArabicWords(decimal) {
  const roundedDecimal = Math.round(decimal * 100);
  if (roundedDecimal === 0) {
    return "";
  } else {
    const decimalWords = convertNumberToArabicWords(roundedDecimal); // Use the Arabic
    return "و " + decimalWords + " درهمًا";
  }
}
const PrintableComponent = React.forwardRef((props, ref) => {

  const [userName, setUserName] = useState('');
  const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));

  const { inID, customerName, Total, DateTime, orderItems } = props.invoiceData;

  console.log("Print " + JSON.stringify(props.invoiceData));
  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setUserName(logedInUser.Name);
    }
  }, []);
  const totalValue = props.invoiceData?.selectedServiceArray?.reduce((sum, item) => sum + (item.data.Sales || 0), 0);

  // Format the total value with the Qatari Riyal symbol (QAR) using Intl.NumberFormat
  const formattedTotal = new Intl.NumberFormat('en-QA', {
    style: 'currency',
    currency: 'QAR',
  }).format(totalValue);

  const totalWordsEn = convertNumberToWords(Math.floor(totalValue));

  let totalWordsAr = convertNumberToArabicWords(Math.floor(totalValue));
  const swappedText = swapLastTwoWords(totalWordsAr);
  console.log(swappedText);
  totalWordsAr = swappedText;
  const decimalPart = totalValue - Math.floor(totalValue);
  const decimalWordsEn = convertDecimalToWords(decimalPart);
  const decimalWordsar = convertDecimalToArabicWords(decimalPart);
  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Handle empty date strings (if any)
    }
    
    const formattedDate = new Date(dateString);
    if (isNaN(formattedDate)) {
      return dateString; // Return the original string if it's not a valid date
    }

    return formattedDate.toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
  };const checkAirlines = () => {
    return props.invoiceData?.selectedServiceArray?.some(
      (item) => item.data?.Airlines !== undefined
    );
  };
  return (
    <div id="invoice" ref={ref}>

      <div className="invoice page-content">
        <div >
          <header>
            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="col">
                <a target="_blank" href="https://lobianijs.com">
                  <img style={{ width: '60%', height: '13%', marginLeft: '-10%' }} src={logo} data-holder-rendered="true" alt="Company Logo" />
                </a>
                <h2 className="name" style={{ fontSize: '12px' }}>
                  <a target="_blank" href="https://lobianijs.com">
                    TAKEOFF TRAVELS AND TOURS <br />CR NO: 122421
                  </a>
                </h2>
                <div>Address: Al Aziziya, Doha, Qatar</div>
                <div>P.O.Box: 82636</div>
                <div>Phone: +974 4444 1662</div>
              </div>
              <div className="company-details" style={{ width: '100%', marginTop: '5%' }}>
                <h2 className="name" style={{ fontSize: '12px' }}>
                  <a target="_blank" href="https://lobianijs.com">
                    تيك اوف  ترافيل اند تورز <br />رقم السجل التجاري  : 122421
                  </a>
                </h2>
                <div>عنوان: العزيزية، دوحة،قطر</div>
                <div>ص.ب  : ٨٢٦٣٦      </div>
                <div>رقم هاتف + ٩٧٤ ٤٤٤٤١٦٦٢ </div>
              </div>
            </div>

          </header>
          {/* <main> */}
          <div class="row contacts">
            <div class="col invoice-to">
              <div class="text-gray-light"> </div>
              <h2 class="to">{props.invoice?.customerName}</h2>
              {/* <div class="address">796 Silver Harbour, TX 79273, US</div>
                              <div class="email"><a href="mailto:john@example.com">john@example.com</a></div> */}
            </div>
            <div class="col invoice-details">

              <table class="col invoice-details" style={{ width: '10%', marginLeft: '30%' }}>
                <thead>
                  <th style={{color:'purple',fontSize:'17px'}} >**************** Quotation *********************</th>
                  {/* <th>REP</th>
                  <th class="date">Date التاريخ</th>
                  <th class="invoice-id">Invoice No # فاتورة</th>*/}
                  </thead> 
                <tbody>
                  {/* <tr>
                    <td></td> */}
                    {/* <td>IR</td>
                    <td>{formatDate(props.invoice?.date)}</td>
                    <td>{props.invoice?.InvoiceNo}</td> */}
                  {/* </tr> */}
                </tbody>
              </table>

              {/* <h1 class="invoice-id"><span>Invoice No / الفاتورة  رقم : </span>  <span style={{ fontSize: 10, color: "black" }}> {props.invoice?.InvoiceNo}</span></h1>
                          <div class="date">Date of Invoice: {props.invoice?.date}</div>
                          <div class="date">Due Date:{props.invoice?.date}</div> */}
            </div>
          </div>
          <div >
            <table style={{ width: '100%' }} border="0" cellspacing="0" cellpadding="0">
              <thead>
                <tr>
                  <th class="no">Sr No.</th>
                  <th class="text-left">Service / الخدمة</th>
                   <th class="text-right">AirLine</th>
                  <th class="text-right">Description / الوصف</th>
                  <th class="no">Amount QAR / كميه ر.ق</th>
                </tr>
              </thead>
              <tbody >
                {props.invoiceData?.selectedServiceArray?.map((item, index) => {

                  return (
                    <tr key={index}>
                      <td class="no">{index + 1}</td>
                      <td class="text-left" style={{ width: '15%' }}>
                        {item.service}
                      </td>
                      <td class="text-left" style={{ width: '15%' }} >
                        {item.data.Airlines}
                      </td>
                      <td >{item.data.Description} </td>
                      <td class="total" style={{ width: '5%', color: 'white' }}>{item.data.Sales}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4" style={{ textAlign: 'left' }}>
                    <h4 style={{ fontSize: '15px', marginBottom: '0', marginTop: '0' }}>Total (المبلغ الإجمالي) :</h4>
                    <br></br>
                    <span lang="en" style={{ fontSize: '15px', textTransform: 'capitalize' }}> Qatari Riyals {totalWordsEn} {decimalWordsEn} Only</span>
                    <br></br>
                    <span lang="ar" style={{ fontSize: '15px', textTransform: 'capitalize' }}> ريال قطرى  {totalWordsAr} {decimalWordsar} فقط  </span>

                  </td>
                  <td>
                    <h5 >{formattedTotal}</h5>

                  </td>
                </tr>
                {/* <tr>
                              <td colSpan="2"></td>
                              <td colSpan="1">Total (المبلغ الإجمالي) <br></br>
                                  <span lang="en" style={{ textTransform: 'capitalize' }}> Qatari Riyals {totalWordsEn} {decimalWordsEn} Only</span>
                                  <br></br>
                                  <span lang="ar" style={{ textTransform: 'capitalize' }}> ريال قطرى  {totalWordsAr} {decimalWordsar} فقط  </span></td>
                              <td> {formattedTotal} <br></br>

                              </td>
                          </tr> */}
                {/* <tr>
                              <td colspan="2"></td>
                              <td colspan="2">TAX 25%</td>
                              <td>$1,300.00</td>
                          </tr> */}
                {/* <tr>
                              <td colspan="2"></td>
                              <td colspan="2">GRAND TOTAL</td>
                              <td>$6,500.00</td>
                          </tr> */}



              </tfoot>
            </table>

          </div>

        </div>

      </div>
      <div class="footer">
        <div class="thanks">
          <table style={{ width: '70%', marginLeft: '15%', backgroundColor: '#eee' }} border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th>
                Payment Terms / شروط الدفع
              </th>
              <th>Payment Details / بيانات الدفع:</th>
            </tr>
            <tr>
              <td>
                -	100% Via Cash/ Bank Transfer/ Card upon booking<br></br>
                -	30% Advance upon booking, balance prior to travel<br></br>
                -	Within 30 days<br></br>

              </td>
              <td>
                Payment Type:  {props.invoice?.Cash}<br></br>
                Name: Take Off Travels and Tours WLL<br></br>
                Bank Name: Qatar Islamic Bank<br></br>
                IBAN : QA96 QISB 0000 0000 0151 5500 0001 4<br></br>
                -	Credit<br></br>


              </td>
            </tr>
          </table>
        </div>
        <br></br>
        <br></br>
        <div class="notices" style={{ width: '70%', backgroundColor: '#eee', marginLeft: '15%' }}>
          <div style={{ fontWeight: 'bold' }}>Terms/ الشروط</div>
          <div class="notice" style={{ fontWeight: 'lighter' }} >-	If the balance is not cleared prior to travel, the agency has the right to cancel the ticket.</div>
        </div>
      </div>
      <div className="print-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>

        <span style={{ fontWeight: 'bold' }}>
          Prepared By / أُعدت بواسطة
          <hr />
          <br />
          <span style={{ fontWeight: 'normal' }}>   Name of Agent: {userName}</span>
        </span>
        <span>Received By / أُعدت بواسطة<hr></hr><br></br></span>
      </div>

    </div>

  );
});

export default PrintableComponent;
